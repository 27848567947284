.movie-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.movie {
  background-color: #373b69;
  border-radius: 3px;
  box-shadow: 3px 5px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 1rem;
  width: 250px;
}

.movie img{
  height: 450px;
  max-width: 100%;
}

.movie-info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.movie-info h3{
  margin: 0;
  color: white;
}

.movie-over{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  background: rgba(0, 0, 0, 0.8);;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  color: white;
  transform: translateY(100%);
  transition: all 0.3s linear;
}

.movie:hover .movie-over{
  transform: translateY(0);
}

.tag{
  background-color: #22254b;
  border-radius: 5px;
  font-weight: bold;
  padding: 0.5rem;
}
