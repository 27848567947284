.container {
  display: flex;
  align-items: center;
  justify-content: center
}

.image {
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-right: 3rem;
  width: 400px;
  height: auto;
}

.text {
  color: white;
  font-size: 20px;
  padding-left: 20px;
}

.btn {
  text-transform: capitalize;
  padding: 0.5rem 0.5rem;
  background: hsl(205, 78%, 60%);
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  margin-top: 1rem;
  letter-spacing: 0.1rem;
  text-decoration: none;
}
